
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Foretag } from '../models/foretag-model';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.css']
})
export class ModalContentComponent implements OnInit {
  @Input() public foretag;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  mapLoad: boolean;
  lat: number = null;
  lng: number = null;
  zoom: number = 12;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    console.log('Init Modal-content' + this.foretag);
    if (this.foretag.foretag_map_lat && this.foretag.foretag_map_lon) {
      this.mapLoad = true;
      this.lat = this.foretag.foretag_map_lat;
      this.lng = this.foretag.foretag_map_lon;
      console.log(this.lat)
    }
  }

  passBack() {
    console.log(this.foretag);
    this.passEntry.emit(this.foretag);
  }
  goToLink(url: Foretag) {
    console.log(url);
    window.open(url.foretag_hemsida, "_blank");
  }
}
