import { Injectable } from "@angular/core";
import { Foretag, Response } from "../models/foretag-model";
import { Search } from "../models/sok-model";
import { Observable } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};
@Injectable()
export class ForetagService {
  // API_URL = "https://trackonnet.eu/nodejs/api";
  // API_URL = 'http://8.196.248.118/nodejs/api';
  //API_URL = 'https://api.nummersoket.se/api';
  //API_URL = 'https://api.tractrix.se/api'; // Ny Address
  API_URL = 'https://customer.trackonnet.net/api2';
  //API_URL = 'http://localhost:3000';
  constructor(private http: HttpClient) { }

  getForetagNamn(): Observable<Response> {
    // Retrieve All Companies
    return this.http.get<Response>(
      this.API_URL + "/programming-languages/"
    );
  }

  getsearchForetag(search_foretag: any): Observable<Response> {
    //console.log(search_foretag);
    return this.http.post<Response>(
      this.API_URL + "/getSoktForetag",
      { search_foretag }
    );
  }

  getsearchForetagList(search_foretag: any): Observable<Response> {
    //console.log(search_foretag);
    return this.http.post<Response>(
      // this.API_URL + "/getSoktForetagList/",
      this.API_URL + "/getSoktForetag/",
      search_foretag
    );
  }
}
