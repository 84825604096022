import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ForetagService } from "../app/services/foretag.service";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalContentComponent } from "./modal-content/modal-content.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AgmCoreModule } from "@agm/core"; // COMPANY SERVICE
import { NgxUiLoaderModule } from "ngx-ui-loader";

@NgModule({
  declarations: [AppComponent, ModalContentComponent],
  imports: [
    BrowserModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDDGxcQRPQktB1xCnZGLwismG8UXhRdJZQ"
    })
  ],
  providers: [ForetagService],
  bootstrap: [AppComponent],
  entryComponents: [ModalContentComponent]
})
export class AppModule {}
