import { Component, OnInit } from "@angular/core";
import { ForetagService } from "../app/services/foretag.service";
import { Search } from "../app/models/sok-model";
import { Foretag } from "../app/models/foretag-model";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ModalContentComponent } from "./modal-content/modal-content.component";
//import { Observable } from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  numberOfRows: number;
  _search: Search;
  searchInputForetag: string;
  foretagsSearch: Foretag[];
  showModal: Boolean;
  title = "Nummerbyrån Sverige AB";

  constructor(
    private _foretagService: ForetagService,
    private modalService: NgbModal,
    private spinnerService: NgxUiLoaderService
  ) {
    this._search = new Search();
  }
  ngOnInit() {
    this.numberOfRows = 0;
    this.showModal = false;
    //document.getElementById("numberOfRows").style.visibility = "hidden";
  }
  public foretagSelected(event: any) {
    if (event) {
      if (this.searchInputForetag.length >= 3) {
        /*
        console.log('Searching');
        this.spinnerService.start();
        this._search.search_foretag = this.searchInputForetag;
        this._foretagService
          .getsearchForetagList(this._search)
          .subscribe(res => {
            console.log('Response was> ' + res);
            this.foretagsSearch = res;
            this.numberOfRows = this.foretagsSearch.length;
            this.spinnerService.stop();
          });
        */
        //console.log('Searching');
        this.spinnerService.start();
        //console.log('Searching');
        this._search.search_foretag = this.searchInputForetag;
        this._foretagService
          .getsearchForetag(this._search.search_foretag)
          //.getForetagNamn() // Foretag[]
          .subscribe(res => { // Response.data
            //console.log({ res });
            //console.log(res.data)
            this.foretagsSearch = res.data;
            this.numberOfRows = this.foretagsSearch.length;
            //console.log(this.foretagsSearch.length);
            this.spinnerService.stop();
          });
      }
    }
  }
  onKeydown(event) {
    if (event.key === "Enter") {
      //console.log(event);
      this.foretagSelected(this.searchInputForetag);
    }
  }
  // When the user clicks the button, open the modal
  btnClicked(item) {
    //this.showModal = true;
    //console.log(item);
    //console.log(this.showModal);
    const modalRef = this.modalService.open(ModalContentComponent);
    modalRef.componentInstance.foretag = item;
  }
}
